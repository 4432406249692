<template>
  <div class="confirm-order-warpper" style="height: 100vh; overflow: hidden">
    <div v-if="storeType === '1'" style="flex: 0 1 1 ;">
      <div class="confirm-order-content">
        <!-- <div v-if="storeType == 2" style="padding:0 1.1rem;">
          <div class="paytype" @click="selectSelectType">
            <div style="display: flex; flex-direction: column;  ">
              <span style="min-width: 5rem; font-weight: 600;">配送方式:</span>
              <span style="font-size: 0.9rem; color: #666; display: flex;flex-direction: row; margin-top: 0.5rem;">{{
                sendTypeStr }}</span>
            </div>
            <van-icon color="#999" style="margin-left: 0.5rem;" name="arrow" size="1rem" />
          </div>
          <div
            style="margin-top: 1rem; display: flex;flex-direction: row; justify-content: space-between; align-items: center;  padding: 0 0 0 0.5rem;"
            @click="SelectAddress">
            <div>
              <span style="min-width: 5rem; font-weight: 600;">配送地址:</span>
              <div style="font-size: 0.9rem; color: #666; display: flex;flex-direction: row; margin-top: 0.5rem;"> <span>
                  {{ address
                  }}</span>
              </div>
            </div>
            <van-icon style="margin-top: 0.15rem;" name="arrow" color="#999" size="1rem" />
          </div>
          <div v-if="sendSelectType == 1"
            style="margin-top: 1rem;margin-bottom: 0.5rem; display: flex;flex-direction: row; justify-content: space-between; align-items: center;  padding: 0 0 0 0.5rem;"
            @click="SelectShopAddress">
            <div>
              <span style="color: #666;min-width: 5rem;font-weight: 600;">商家地址</span>
              <div style="font-size: 0.9rem; color: #666; display: flex;flex-direction: row; margin-top: 0.5rem;"> <span>
                  {{ orderInfo.shopDetail
                  }}</span>
              </div>
            </div>
            <van-icon style="margin-top: 0.15rem;" name="arrow" color="#999" size="1rem" />
          </div>
          <img src="../../assets/images/caitiao.svg" alt="" class="cai" />
        </div> -->
        <div>
          <order-head :name="orderInfo.consigneeName" :tel="orderInfo.consigneePhone" :address="getAddress()"
            @EditAddress="editAddress"></order-head>
        </div>
      </div>
    </div>
    <div style="overflow: scroll;flex: 1 1 1 ;padding-bottom: 3rem;margin-top: 1rem;">
      <div v-for="(item, index) in orderInfo?.productSubOrderList" @click="changeItem(index)">
        <div v-if="index != 0" style="border-bottom: 1px dashed #999;margin: 0 1.3rem;"> </div>
        <div class="bgc" style="padding: 1.2rem 1.1rem;">
          <div class="flex">
            <div>
              <img v-if="item.coverImage" :src="item.coverImage" alt="" class="img" />
            </div>
            <div style="font-size: 1rem;flex: 1 1; height: 6.5rem; padding-left: 0.85rem;">
              <span class="textTitle"> {{ item.productName }}</span>
              <span class="textcontext"> {{ item.skuName }}</span>
              <div style="width: 100%;text-align: end;margin-top: 0.8rem;">
                <span style="font-size: 1rem;margin-top: 0.3rem; ">x {{ item.emptionNum }}</span>
              </div>
              <div style="color: #D33B2E; margin-right: 0.2rem; margin-top: 0.2rem; width: 100%;text-align: end;">
                {{ getPrice(item.emptionNum, item.showPrice) }}点
              </div>
            </div>
          </div>
          <div v-if="item.blessings" class="sendjcsb" style="border-top: 1px solid #ededed; margin-top: 0.5rem;">
            <span style=" margin: 0.5rem 0; ">祝福语：{{ item.blessings }}</span>
          </div>
          <div v-if="item.buyerMsg" class="sendjcsb" style="border-top: 1px solid #ededed; margin-top: 0.5rem;">
            <span style=" margin: 0.5rem 0; ">备注：{{ item.buyerMsg }}</span>
          </div>
          <div v-if="!item.deliveryFlag">
            <div v-if="item.productFlag" class="sendjcsb" style="border-top: 1px solid #ededed; margin-top: 0.5rem;">
              <span style=" color: #D33B2E; margin: 0.5rem 0;font-size: 0.85rem; "> 配送错误，请重新选择配送方式 </span>
            </div>
            <div v-else class=" sendjcsb" style="border-top: 1px solid #ededed; margin-top: 0.5rem;">
              <span style="color: #D33B2E; margin: 0.5rem 0;font-size: 0.85rem; "> 该地址不支持配送 </span>
            </div>
          </div>
          <div v-else>
            <div class="sendjcsb" style="border-top: 1px solid #ededed; margin-top: 0.5rem;">
              <span style=" margin: 0.5rem 0; color: #888;">配送方式</span>
              <span style=" margin: 0.5rem 0; ">{{ getSendType0(item) }}</span>
            </div>
            <div v-if="!ifShoow(item) && item.productFlag" class="sendjcsb">
              <span style=" margin: 0.5rem 0; color: #888;min-width: 5rem;">配送地址</span>
              <div style=" margin: 0.5rem 0; text-align: right;">
                <div style=" text-align: right;">{{
                  item.consigneeAddress
                  }}</div>
                <div style=" text-align: right;font-size: 0.9rem;color: #666; ">{{
                  item.consigneeName + '-' + $utils.formPhone(item.consigneePhone)
                  }}</div>
              </div>
            </div>

            <div v-if="(item.shipType && item.shipDate) || (item.ship_type && item.ship_Date)" class="sendjcsb">
              <span style=" margin: 0.5rem 0 ; color: #888;">{{ getSendType1(item) }}</span>
              <span style=" margin: 0.5rem 0; ">{{ getSendType2(item) }}</span>
            </div>
            <div v-if="ifShoow(item)" class="sendjcsb">
              <span style=" margin: 0.5rem 0; color: #888;min-width: 5rem;">门店名称</span>
              <span style=" margin: 0.5rem 0; ">{{ item.shopName ? item.shopName :
                item.take_shop_name
                }}</span>
            </div>
            <div v-if="ifShoow(item)" class="sendjcsb">
              <span style=" margin: 0.5rem 0; color: #888;min-width: 5rem;">门店地址</span>
              <span style=" margin: 0.5rem 0; ">{{ item?.shopDetail ? item?.shopDetail : item?.addr
                }}</span>
            </div>
          </div>
        </div>
        <div style="border-top:1px dashed #888 ; margin: 0 1.6rem;"></div>

      </div>
      <div style="border-top:1px dashed #888 ; margin: 0 1.6rem;"></div>
      <div v-if="showPostMoney"
        style=" background-color:white ;border-radius: 0.5rem;margin: 0 0.8rem;border-radius: 0.5rem;">
        <div style="display: flex;flex-direction: row;justify-content: space-between; padding: 1.1rem">
          <span style="color: #666;font-size: 1rem; "> 总运费</span>
          <span v-if="orderInfo.postMoney && orderInfo.postMoney > 0" style="font-size: 1rem; "> {{ orderInfo.postMoney
            }}点</span>
          <span v-else style="font-size: 1rem; "> 包邮</span>
        </div>
      </div>
      <div class="scarcegoods" @click="selectScarce">
        <span style="color: #666;font-size: 1rem; "> 如遇缺货</span>
        <span style="font-size: 1rem; "> {{ scarceStr }} <van-icon name="arrow" color="#999" /></span>
      </div>
      <div style="border-top:1px dashed #888 ; margin: 0 1.6rem;"></div>
      <div style=" background-color:white ;border-radius: 0.5rem;margin: 0 0.8rem;border-radius: 0.5rem;">
        <div style="display: flex;flex-direction: row;justify-content:flex-end;padding: 1.1rem;">
          <span style="font-size: 1rem;color: #666;">合计:</span>
          <span style="font-size: 1.05rem; font-weight: 600;">{{ orderInfo?.payProductTotal }}点</span>
        </div>
      </div>

      <div style="color: #D33B2E;font-size: 0.8rem;margin: 1rem;">提示：为了保障您的兑换体验，本商品不支持无理由退换，请仔细挑选~ </div>
    </div>
    <send-type v-if="SelectSend" :columns="DeliveryRules" @TypeConfirm="TypeConfirm" @TypeCancel="TypeCancel"
      @closed="closeSelectSend">
    </send-type>

    <select-address v-if="showSelectAddress" :address-list="addressList" @closed="closedadd"
      @seleceAddressItem="seleceAddressItem"> </select-address>
    <shop-address v-if="showShopAddress" :address="ShopDetail" @closed="closedshop"
      @seleceShopItem="seleceShopItem"></shop-address>
    <scarce-type v-if="ScarceShow" @closed="closedScarce" @selectScarceItem="selectScarceItem"></scarce-type>
    <!-- 底部 -->
    <Footer :orderInfo="orderInfo" :type="storeType" :can-by="deliveryFlag" :text="toaddBuy"></Footer>
  </div>
</template>

<script>
import OrderHead from "../../components/order/OrderHead.vue";
import ScarceType from "../../components/order/ScarceType.vue";
import SelectAddress from "../../components/goods/SelectAddress.vue";
import ShopAddress from "../../components/goods/ShopAddress.vue";
import SendType from "../../components/goods/SendType.vue";
import { Checkbox, CheckboxGroup } from "vant";
import Footer from "./Footer.vue";
export default {
  name: "confirmOrder",
  props: {},
  data() {
    return {
      showAddress: true,
      toaddBuy: '结算',//提交订单
      storeType: '1', // 1.购物车进入  2.详情进入
      queryCode: '',
      userId: "", // 用户id
      freecaId: "", // 福利卡id
      orderInfo: {},

      productSubOrderList: [], // 商品详情
      SelectSend: false,
      DeliveryRules: [],
      sendTypeStr: '',
      sendTypeTitle: '地址',
      showSelectAddress: false,
      addressList: [],
      deliveryFlag: true,//是否支持配送
      show: false,
      addressType: '',
      address: '',
      addressList: [],
      ShopDetail: null,
      userId: '',
      chousetype: '',
      selectPrice: '',
      selectMarketprice: '',
      showSelectAddress: false,
      goodNum: 1,
      specId: '',
      DeliveryRules: [],
      SelectSend: false,
      showShopAddress: false,
      sendSelectType: 0, //1 自提  2 快递 3 商户
      dsUserId: '',
      dsId: '',
      shipDate: '',
      shipTimeText: '',
      shopDetail: '',
      shopId: '',
      shopName: '',
      consigneeAddress: '',
      consigneeName: '',
      consigneePhone: '',
      addressId: '',
      scarceStr: '与我联系',
      ScarceShow: false,
      showPostMoney: false,
    };
  },
  components: { Checkbox, CheckboxGroup, Footer, OrderHead, SendType, SelectAddress, ShopAddress, ScarceType },
  methods: {
    selectScarceItem(item) {
      this.scarceStr = item.name
      this.orderInfo.outOfStockStatus = item.status
      this.ScarceShow = false
    },
    closedScarce() {
      this.ScarceShow = false
    },
    selectScarce() {
      this.ScarceShow = true
    },
    closedshop(val) {
      this.showShopAddress = val
    },
    seleceShopItem(val) {
      // this.address = val.shop_name + " " + val.address
      this.orderInfo.shopDetail = val.address
      this.orderInfo.shopId = val.shop_id
      this.orderInfo.shopName = val.shop_name
      this.showShopAddress = false
    },

    SelectAddress() {
      if (this.addressList)
        this.showSelectAddress = true
      else {
        this.$router.push("/EditAddress");
      }
    },
    SelectShopAddress() {
      if (this.ShopDetail) {
        this.showShopAddress = true
      }
    },
    onShowPostMoney() {
      this.orderInfo?.productSubOrderList.forEach(element => {
        if (element.shipType && element.shipType !== 'shop') {
          this.showPostMoney = true
        }
      });
    },
    // 商家地址
    getShopList() {
      this.$api
        .getShopList(this.orderInfo.cityCode, 47)
        .then((res) => {
          if (res.code === 0) {
            this.ShopDetail = res.data
            if (this.ShopDetail?.shops.length > 0) {
              this.orderInfo.shopDetail = this.ShopDetail?.shops[0].address
              this.orderInfo.shopId = this.ShopDetail?.shops[0].shop_id
              this.orderInfo.shopName = this.ShopDetail?.shops[0].shop_name
            }
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    },
    TypeCancel() {
      this.SelectSend = false
    },
    queryDistributionRules() {
      let form = {
        cityCode: this.orderInfo.cityCode,
        quantitys: this.orderInfo.productNum,
        distributionRuleId: this.orderInfo.cakeSubOrderList[0].productId,
        specId: this.orderInfo.cakeSubOrderList[0].skuId,
        addrId: this.dsId
      }
      this.$api
        .queryDistributionRules(form)
        .then((res) => {
          if (res.code == 0) {
            if (res.data) {
              this.DeliveryRules = []
              if (res.data.can_same == '1') {
                let rules = { text: '快递配送', children: [] }
                if (res.data.validate_same_row.length > 0) {
                  rules.children.text = ''
                  let child3 = { text: '运费', children: [] }
                  if (res.data.validate_same_row[0].delivery_amount == '0') {
                    this.sendTypeStr = '快递配送 免运费'//res.data.validate_same_row[0].delivery_amount
                    this.orderInfo.postMoney = 0
                  } else {
                    this.sendTypeStr = '快递配送 运费' + "" + res.data.validate_same_row[0].delivery_amount + "点 "//res.data.validate_same_row[0].delivery_amount
                    this.orderInfo.postMoney = res.data.validate_same_row[0].delivery_amount
                  }
                  this.sendSelectType = 2
                  res.data.validate_same_row.forEach(element => {
                    let children = { text: "" }
                    if (element.delivery_amount == '0') {
                      children.text = '免运费'
                    } else {
                      children.text = element.delivery_amount
                    }
                    child3.children.push(children)
                  });
                  rules.children.push(child3)
                }
                this.DeliveryRules.push(rules)
              }
              if (res.data.can_take == '1') {
                let rules1 = { text: '门店自提', children: [] }
                if (res.data.validate_take_dates.length > 0) { //
                  res.data.validate_take_dates.forEach(element => { //
                    let child = { text: '', children: [] }
                    child.text = element.date_text
                    let time = element.validate_take_times //validate_take_times
                    if (time) {
                      time.forEach(element => {
                        let children = { text: "" }
                        children.text = element
                        child.children.push(children)
                      });
                    }
                    rules1.children.push(child)
                  });
                  this.sendTypeStr = '门店自提 ' + rules1.children[0].text + " " + rules1.children[0].children[0].text
                  this.orderInfo.shipDate = rules1.children[0].text
                  this.orderInfo.shipTimeText = rules1.children[0].children[0].text
                  this.sendSelectType = 1
                }
                this.DeliveryRules.push(rules1)
                this.getShopList()
              }
              if (res.data.can_ship == '1') {
                let rules2 = { text: '商家配送', children: [] }
                if (res.data.validate_delivery_dates.length > 0) {
                  res.data.validate_delivery_dates.forEach(element => {
                    let child = { text: '', children: [] }
                    child.text = element.date_text
                    let time2 = JSON.parse(element.validate_delivery_times)
                    if (time2) {
                      time2.forEach(element => {
                        let children = { text: "" }
                        children.text = element
                        child.children.push(children)
                      });
                    }
                    rules2.children.push(child)
                  });
                  this.sendTypeStr = '商家配送 ' + rules2.children[0].text + " " + rules2.children[0].children[0].text
                  this.orderInfo.shipDate = rules2.children[0].text
                  this.orderInfo.shipTimeText = rules2.children[0].children[0].text
                  this.sendSelectType = 3
                }
                this.DeliveryRules.push(rules2)
              }

            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    closeSelectSend(val) {
      this.SelectSend = val
    },
    // 添加地址
    addCakeAddress(item) {
      if (item.dsId == 0) {
        let form = {
          addr: item.address + item.houseNum,
          area: item.addressQname,
          cityName: item.addressCname,
          isDefault: item.defaultFlag,
          lat: item.lat,
          lng: item.lng,
          name: item.recipient,
          phone: item.recipientMobile,
          addressId: item.id
        }
        this.$api
          .addCakeAddress(form)
          .then((res) => {
            if (res.code === 0) {
              item.dsUserId = res.data.user_id
              this.dsUserId = res.data.user_id
              item.dsId = res.data.id
              this.dsId = res.data.id
              this.orderInfo.dsId = this.dsId
              this.orderInfo.dsUserId = this.dsUserId
            }
          })
          .catch((err) => {
            // console.log(err);
          });
      } else {
        this.dsUserId = item.dsUserId
        this.dsId = item.dsId
        this.orderInfo.dsId = this.dsId
        this.orderInfo.dsUserId = this.dsUserId

      }
      this.changeAddress()
      // this.queryDistributionRules()
    },
    canSend() {
      let form = []
      this.orderInfo?.productSubOrderList?.forEach(element => {
        if (!element.productFlag) {
          form.push({
            addressId: this.orderInfo.addressId,
            userId: this.orderInfo.addressId,
            productId: element.productId,
            skuId: element.skuId,
            productNum: element.emptionNum,
            productPrice: element.showPrice,
            shipType: 'same',
            status: "0",
            supplierId: element.supplierId,
            productName: element.productName,
            coverImage: element.coverImage,
            consigneeAddress: this.orderInfo.consigneeAddress,
            consigneeName: this.orderInfo.consigneeName,
            consigneePhone: this.orderInfo.consigneePhone,
            costPrice: element.costPrice,
            cardId: this.freecaId,
            type: '自营',
            cityCode: this.orderInfo.cityCode,
            category1Id: element.category1Id,
            category1Name: element.category1Name,
            category2Id: element.category2Id,
            category2Name: element.category2Name,
          })
        }
      });
      this.$api.checkIsDelivery(form).then((res) => {
        this.getShopInfo(res.data)
      })
        .catch((err) => {
          // console.log(err);
        });
    },
    async changeAddress() {

      const res = await this.$api.reCountExpressFee(this.queryCode, this.orderInfo.addressId);
      if (res.data) {
        this.deliveryFlag = true
      }
      this.orderInfo = res.data;
      this.orderInfo?.productSubOrderList.forEach(element => {
        if (!element.deliveryFlag) {
          this.deliveryFlag = false
        }
      });
    },
    closedadd(val) {
      this.showSelectAddress = val
    },
    seleceAddressItem(item) {
      this.orderInfo.addressId = item.id
      this.orderInfo.consigneeName = item.recipient
      this.orderInfo.consigneePhone = item.recipientMobile
      this.orderInfo.addressCname = item.addressCname
      this.orderInfo.addressQname = item.addressQname
      this.orderInfo.addressPname = item.addressPname
      this.orderInfo.consigneeAddress = item.addressPname + item.address + item.houseNum
      // if (this.storeType == 2) {
      //   this.address = this.getAddress()
      // }
      this.addCakeAddress(item)
      this.showSelectAddress = false

    },
    getMyAddress() {
      this.$api
        .getAddress(this.userId)
        .then((res) => {
          if (res.code == 1) {
            this.$Toast(res.msg);
            return
          }
          this.addressList = res.data;
          this.addressList.forEach(element => {
            if (element.defaultFlag == '1') {
              if (!this.orderInfo.consigneeName) {
                this.orderInfo.addressId = element.id
                this.orderInfo.consigneeName = element.recipient
                this.orderInfo.consigneePhone = element.recipientMobile
                this.orderInfo.addressCname = element.addressCname
                this.orderInfo.addressQname = element.addressQname
                this.orderInfo.addressPname = element.addressPname
                this.orderInfo.consigneeAddress = element.addressPname + element.address + element.houseNum
                this.addCakeAddress(element)
              }

            }
          });
          if (!this.orderInfo.consigneeName) {
            this.orderInfo.addressId = this.addressList[0].id
            this.orderInfo.consigneeName = this.addressList[0].recipient
            this.orderInfo.consigneePhone = this.addressList[0].recipientMobile
            this.orderInfo.addressCname = this.addressList[0].addressCname
            this.orderInfo.addressQname = this.addressList[0].addressQname
            this.orderInfo.addressPname = this.addressList[0].addressPname
            this.orderInfo.consigneeAddress = this.addressList[0].addressPname + this.addressList[0].address + this.addressList[0].houseNum
            this.addCakeAddress(this.addressList[0])
          }


          if (!this.addressList || this.addressList.length == 0) {
            this.addressTag()
          }
        })
        .catch();
    },
    addressTag() {
      Dialog.confirm({
        title: "提示",
        message: "请先设置收货地址",
        confirmButtonText: '去设置'
      }).then(() => {
        this.$router.push("/EditAddress");
      })
    },

    setItemAddress(item) {
      this.orderInfo.productSubOrderList.forEach(element => {
        if (!element.productFlag) {
          element.addressId = item.id
        }
      });
    },
    setSendTypeTitle() {
      if (this.sendTypeStr) {
        if (this.sendTypeStr.includes('快递配送') || this.sendTypeStr.includes('商家配送')) {

          this.sendTypeTitle = '配送地址'
          if (this.sendTypeStr.includes('快递配送')) {
            this.sendSelectType = 2
            this.orderInfo.shipType = 'same'
          } else {
            this.orderInfo.shipType = 'delivery'
            this.sendSelectType = 3
          }
          if (this.addressList.length > 0) {
            this.orderInfo.consigneeAddress = this.addressList[0].addressPname + this.addressList[0].address + this.addressList[0].houseNum
            this.orderInfo.consigneeName = this.addressList[0].recipient
            this.orderInfo.consigneePhone = this.addressList[0].recipientMobile
            this.orderInfo.addressId = this.addressList[0].id
          }
        } else if (this.sendTypeStr.includes('门店自提')) {
          this.sendTypeTitle = '商家地址'
          this.sendSelectType = 1
          this.orderInfo.shipType = 'shop'
          if (this.ShopDetail?.shops.length > 0) {
            // this.address = this.ShopDetail?.shops[0].address
            this.orderInfo.shopDetail = this.ShopDetail?.shops[0].address
            this.orderInfo.shopId = this.ShopDetail?.shops[0].shop_id
            this.orderInfo.shopName = this.ShopDetail?.shops[0].shop_name
          }
        }
      }
    },
    getSendType0(val) {
      if (val.shipType) {
        if (val.shipType == 'delivery') {
          return '商家配送'
        } else if (val.shipType == 'shop') {
          return '门店自提'
        } else {
          return '快递配送'
        }
      } else {
        if (val.ship_type == 'delivery') {
          return '商家配送'
        } else if (val?.ship_type == 'shop') {
          return '门店自提'
        } else {
          return '快递配送'
        }
      }

    },
    ifShoow(val) {
      if (val?.shipType && val?.shipType == 'shop') {
        return true
      } else if (val?.ship_type && val?.ship_type == 'shop') {
        return true
      } else {
        return false
      }
    },
    getSendType1(val) {
      if (val.shipType && val.shipDate) {
        if (val.shipType == 'delivery') {
          return '配送时间'
        } else if (val.shipType == 'shop') {
          return '自提时间'
        } else {
          return '配送时间'
        }
      } else if (val.ship_type && val.ship_date) {
        if (val?.ship_type == 'delivery') {
          return '配送时间'
        } else if (val?.ship_type == 'shop') {
          return '自提时间'
        } else {
          return '配送时间'
        }
      }
    },
    getSendType2(val) {
      if (val.shipType && val.shipDate) {
        return val.shipDate + ' ' + val.shipTimeText
      } else if (val.ship_type && val.ship_date) {
        return this.$moment(val.ship_date).format("MM月DD日") + ' ' + val.ship_time_text
      }
    },
    TypeConfirm(value) {
      this.SelectSend = false
      if (value) {
        let val = ''
        let isSend = false
        value.forEach((element, index) => {
          val += element + ' '
          if (index == 1) {
            this.orderInfo.shipDate = element
            if (element.includes('运费')) {
              isSend = true
              this.orderInfo.shipDate = ''
            }
          }
          if (index == 2) {
            this.orderInfo.shipTimeText = element
            if (isSend) {
              if (element == '免运费') {
                this.orderInfo.postMoney = 0
                this.orderInfo.shipTimeText = ''
                val = val.replace('运费', ' ')
              } else {
                this.orderInfo.postMoney = element
              }
            }
          }
        });
        this.sendTypeStr = val
      }
      this.setSendTypeTitle()
    },

    initState() {
      const queryData = this.$route.query; // 地址栏参数
      const userInfo = localStorage.getItem("userInfo"); // 用户信息
      this.userId = userInfo ? JSON.parse(userInfo).id : "";
      this.freecaId = localStorage.getItem("cardId");
      if (queryData) {
        const { code, type } = queryData;
        this.queryCode = code;
        this.storeType = type;
        this.getShopInfo(code);
      }

    },
    onEdit() { },
    handleStoreTypeChange() {
      // 商户类型切换change函数
    },
    // 获取购物车确认商品信息
    async getShopInfo(code) {
      if (!code) return;
      const res = await this.$api.queryCheckIsDelivery(code);
      this.orderInfo = res.data;
      this.orderInfo.outOfStockStatus = '0'
      if (this.orderInfo.productFlag) {
        this.getMyAddress()
      }

      this.onShowPostMoney()
      this.getSendType()
      this.productSubOrderList = res.data.productSubOrderList
      if (this.orderInfo) {
        this.deliveryFlag = true
      }
      this.orderInfo = res.data;
      this.orderInfo?.productSubOrderList.forEach(element => {
        if (!element.deliveryFlag) {
          this.deliveryFlag = false
        }
      });
    },
    getPrice(num, price) {
      return parseFloat(parseFloat(num) * parseFloat(price)).toFixed(2)
    },
    getAddress() {
      return this.orderInfo.addressCname + this.orderInfo.addressQname + this.orderInfo.addressPname + this.orderInfo.consigneeAddress
    },
    editAddress() {
      this.showSelectAddress = true
    },
    getSendType() {
      if (this.orderInfo?.shipType == 'shop') {
        this.sendTypeStr = '门店自提 ' + this.getString(this.orderInfo?.shipDate) + this.getString(this.orderInfo?.shipTimeText)
      } else if (this.orderInfo?.shipType == 'same') {
        this.sendTypeStr = '快递配送 ' + this.getString(this.orderInfo?.shipDate) + this.getString(this.orderInfo?.shipTimeText)
      } else {
        this.sendTypeStr = '商家配送 ' + this.getString(this.orderInfo?.shipDate) + this.getString(this.orderInfo?.shipTimeText)
      }
    },
    selectSelectType() {
      this.SelectSend = true
    },
    getString(val) {
      if (!val) {
        return ''
      } else {
        return val
      }
    }
  },

  // beforeRouteLeave(to, from, next) {
  //   if (to.path === '/Cashier') {
  //     from.meta.keepAlive = true
  //   } else {
  //     from.meta.keepAlive = false
  //   }
  //   next()
  // },
  beforeRouteLeave(to, from, next) {
    if (to.path === '/MovieForGoodsCashier') {
      to.meta.keepAlive = false
    }
    next()
  },
  mounted() {
    this.initState()
  },
  computed: {
    name() {
      return this.$store.state.nickname;
    },
    // 计算总价
    sum() {
      let sum = 0;
      this.carData.map((a) => {
        if (a.check) {
          return (sum += a.productNum * a.productPrice);
        }
      });
      if (sum === 0) {
        return sum;
      } else {
        return `${sum.toFixed(2)}点`;
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.confirm-order-warpper {
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;

  .confirm-order-content {
    padding: 0.6rem 0 0 0;
    background-color: white;
    border-radius: 0.5rem;
    margin: 1rem 0.8rem 0 0.8rem;
  }
}

.bgc {
  background-color: #fff;
  border-radius: 0.5rem;
  margin: 0 0.8rem;
}

.paytype {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-bottom: 1px dashed #999;
  padding: 0 0 1rem 0.5rem;
  margin-top: 0.5rem;
  align-items: center;
}

.sendjcsb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1rem;
}

.cai {
  width: 100%;
}

.textTitle {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #464646;
  margin-bottom: 0.5rem;
  font-size: 1rem;
}

.textcontext {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  color: #666;
  margin-bottom: 0.5rem;
  font-size: 0.85rem;

}

.scarcegoods {
  background-color: white;
  border-radius: 0.5rem;
  margin: 0 0.8rem;
  border-radius: 0.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1.1rem
}

.img {
  width: 6.5rem;
  height: 6.5rem;
  border-radius: 0.5rem;
}
</style>
